
document.addEventListener("DOMContentLoaded", function () {
   var menu = document.getElementById('header-navbar');
   var spacer = document.getElementById('spacer');
   var observer = new IntersectionObserver(function (entries) {
     entries.forEach(function (entry) {
       if (!entry.isIntersecting) {
         menu.classList.add('scrolled');
       } else {
         menu.classList.remove('scrolled');
       }
     });
   }, { threshold: 0.1 });
   if(spacer){
     observer.observe(spacer);
   }
});