document.addEventListener("DOMContentLoaded", function () {

  var element = document.getElementById("table-pricing");
  const box_saved_1st_month = document.querySelectorAll(".box-saved-1st-month");
  if (box_saved_1st_month) {
    box_saved_1st_month.forEach((el) => {
      el.classList.add("active");
    });
  }
  if (element) {
    var observer = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            // Element is in the viewport, execute your code here

            /*
              Scroll table pricing
            */
            const table_pricing = document.getElementById("table-pricing");
            if (window.innerWidth < 768) {
              if (table_pricing) {
                table_pricing.scrollLeft += 240;
              }
            }
            /* 
            change price table pricing
            */
            const dropdowns = document.querySelectorAll(".dropdown");
            if (dropdowns) {
              dropdowns.forEach((dropdown) => {

                const select = document.querySelector(".select");
                const caret = document.querySelector(".caret");
                const menu = document.querySelector(".menu");
                const options = document.querySelectorAll(".menu li");
                const selected = document.querySelector(".selected");
                select.addEventListener("click", () => {
                  caret.classList.toggle("caret-rotate");
                  menu.classList.toggle("menu-open");
                });

                options.forEach((option) => {
                  option.addEventListener("click", () => {
                    const price_base_marketing_design = document.getElementById("disc_marketing_design");
                    const price_base_video_animation = document.getElementById("disc_video_animation");
                    const price_base_agency_white_label = document.getElementById("disc_agency_white_label");
                  
                    // Lấy giá gốc từ thuộc tính data-base-price
                    const base_price_marketing_design = parseFloat(price_base_marketing_design.dataset.basePrice);
                    const base_price_video_animation = parseFloat(price_base_video_animation.dataset.basePrice);
                    const base_price_agency_white_label = parseFloat(price_base_agency_white_label.dataset.basePrice);

                    const promo_code = price_base_marketing_design.dataset.promoCode;
                    const discount_percentage = price_base_marketing_design.dataset.discountPercentage;
                  
                    let disc_marketing_design = 0;
                    let disc_video_animation = 0;
                    let disc_agency_white_label = 0;
                  
                    let save_marketing_design = 0;
                    let save_video_animation = 0;
                    let save_agency_white_label = 0;
                  
                    const link_marketing_design = document.getElementById("link_marketing_design");
                    const link_video_animation = document.getElementById("link_video_animation");
                    const link_agency_white_label = document.getElementById("link_agency_white_label");
                  
                    const url_marketing_design = new URL(link_marketing_design.href);
                    const url_video_animation = new URL(link_video_animation.href);
                    const url_agency_white_label = new URL(link_agency_white_label.href);
                  
                    const discountValue = parseFloat(option.getAttribute("data-value"));
                  
                    // Cập nhật URL theo option
                    if (discountValue === 0.15) {
                      url_marketing_design.searchParams.set("duration", "quarterly");
                      url_video_animation.searchParams.set("duration", "quarterly");
                      url_agency_white_label.searchParams.set("duration", "quarterly");
                      //delete url params coupon
                      url_marketing_design.searchParams.delete("affiliate");
                      url_video_animation.searchParams.delete("affiliate");
                      url_agency_white_label.searchParams.delete("affiliate");
                    } else if (discountValue === 0.25) {
                      url_marketing_design.searchParams.set("duration", "yearly");
                      url_video_animation.searchParams.set("duration", "yearly");
                      url_agency_white_label.searchParams.set("duration", "yearly");
                      //delete url params coupon
                      url_marketing_design.searchParams.delete("affiliate");
                      url_video_animation.searchParams.delete("affiliate");
                      url_agency_white_label.searchParams.delete("affiliate");
                    } else if (discountValue === 1) {
                      url_marketing_design.searchParams.set("duration", "monthly");
                      url_video_animation.searchParams.set("duration", "monthly");
                      url_agency_white_label.searchParams.set("duration", "monthly");

                      url_marketing_design.searchParams.set("affiliate", promo_code);
                      url_video_animation.searchParams.set("affiliate", promo_code);
                      url_agency_white_label.searchParams.set("affiliate", promo_code);
                    }
                  
                    link_marketing_design.href = url_marketing_design.toString();
                    link_video_animation.href = url_video_animation.toString();
                    link_agency_white_label.href = url_agency_white_label.toString();
                  
                    // Tính toán giá sau giảm và lưu
                    if (discountValue === 0.15 || discountValue === 0.25) {
                      save_marketing_design = (base_price_marketing_design * discountValue).toFixed(1);
                      disc_marketing_design = (base_price_marketing_design - save_marketing_design).toFixed(1);
                      price_base_marketing_design.innerText = disc_marketing_design;
                      document.getElementById("price_saved_marketing_design").innerText = save_marketing_design;
                  
                      save_video_animation = (base_price_video_animation * discountValue).toFixed(1);
                      disc_video_animation = (base_price_video_animation - save_video_animation).toFixed(1);
                      price_base_video_animation.innerText = disc_video_animation;
                      document.getElementById("price_saved_video_animation").innerText = save_video_animation;
                  
                      save_agency_white_label = (base_price_agency_white_label * discountValue).toFixed(1);
                      disc_agency_white_label = (base_price_agency_white_label - save_agency_white_label).toFixed(1);
                      price_base_agency_white_label.innerText = disc_agency_white_label;
                      document.getElementById("price_saved_agency_white_label").innerText = save_agency_white_label;

                    } else if (discountValue === 1) {
                      // Reset về giá gốc
                      if(discount_percentage > 0){
                        price_base_marketing_design.innerText = new Intl.NumberFormat().format(base_price_marketing_design - (base_price_marketing_design * discount_percentage/100));
                        price_base_video_animation.innerText = new Intl.NumberFormat().format(base_price_video_animation - (base_price_video_animation * discount_percentage/100));
                        price_base_agency_white_label.innerText = new Intl.NumberFormat().format(base_price_agency_white_label - (base_price_agency_white_label * discount_percentage/100));
                      }else{
                        price_base_marketing_design.innerText = new Intl.NumberFormat().format(base_price_marketing_design);
                        price_base_video_animation.innerText = new Intl.NumberFormat().format(base_price_video_animation);
                        price_base_agency_white_label.innerText = new Intl.NumberFormat().format(base_price_agency_white_label);
                      }
                      
                    }
                  
                    // Cập nhật hiển thị box-saved
                    const box_saved_1st_month = document.querySelectorAll(".box-saved-1st-month");
                    const box_saved = document.querySelectorAll(".box-saved");
                    if (discountValue !== 1) {
                      box_saved.forEach((el) => el.classList.add("active"));
                      box_saved_1st_month.forEach((el) => el.classList.remove("active"));
                    } else {
                      box_saved.forEach((el) => el.classList.remove("active"));
                      box_saved_1st_month.forEach((el) => el.classList.add("active"));
                    }
                  
                    // Cập nhật trạng thái dropdown
                    const selected = document.querySelector(".selected");
                    const caret = document.querySelector(".caret");
                    const menu = document.querySelector(".menu");
                    const options = document.querySelectorAll(".menu li");
                  
                    selected.innerHTML = option.innerHTML;
                    caret.classList.remove("caret-rotate");
                    menu.classList.remove("menu-open");
                    options.forEach((opt) => opt.classList.remove("active"));
                    option.classList.add("active");
                  });
                });
              });
            }
            // Add your code here

            // Stop observing the element since we only need to check once
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null, // Use the viewport as the container
        rootMargin: "0px",
        threshold: 0.1, // Adjust the threshold as needed
      }
    );

    // Start observing the element
    observer.observe(element);
  }
});
