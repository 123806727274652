let swiperInitialized = false;

// Function to load Swiper
const lazyLoadSwiper = async () => {
    if (swiperInitialized) return;
    swiperInitialized = true;

    try {
        const { default: Swiper } = await import('swiper/bundle');
        // await // Lazy load Swiper styles

        const swiperContainers = document.querySelectorAll(".swiper-container");
        swiperContainers.forEach((container) => {
            const pagination = container.querySelector('.swiper-pagination');
            const nextButton = container.querySelector('.swiper-button-next');
            const prevButton = container.querySelector('.swiper-button-prev');
            // 1
            if (container.classList.contains('swiper-1')) {
              new Swiper(container, {
                slidesPerView: 1,
                loop: true,
                // spaceBetween: 30,
                // centeredSlides: true,
                // autoplay: {
                //   delay: 10000,
                //   disableOnInteraction: false,
                // },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                // breakpoints: {
                //   768: {
                //     slidesPerView: 1.7,
                //   },
                // },
              });
            }
            // 1.7-1
            if (container.classList.contains('swiper-171')) {
              new Swiper(container, {
                slidesPerView: 1,
                loop: true,
                spaceBetween: 30,
                centeredSlides: true,
                autoplay: {
                  delay: 10000,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  768: {
                    slidesPerView: 1.7,
                  },
                },
              });
            }
            // 2-1 no center
            if (container.classList.contains('swiper-21')) {
              new Swiper(container, {
                slidesPerView: 1,
                loop: true,
                spaceBetween: 25,
                autoplay: {
                  delay: 10000,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  1024: {
                    slidesPerView: 2,
                  },
                },
              });
            }
            // 2-1 center lp-service
            if (container.classList.contains('swiper-21c-lp-service')) {
              new Swiper(container, {
                slidesPerView: 3,
                loop: true,
                spaceBetween: 16,
                centeredSlides: true,
                autoplay: {
                  delay: 2500,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  1024: {
                    slidesPerView: 2,
                  },
                },
              });
            }
            // 4-3-2-1
            if (container.classList.contains('swiper-4321')) {
              new Swiper(container, {
                slidesPerView: 1,
                loop: true,
                spaceBetween: 25,
                autoplay: {
                  delay: 7000,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  1400: {
                    slidesPerView: 4,
                  },
                  1024: {
                      slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                },
              });
            }
            if (container.classList.contains('swiper-54321')) {
              new Swiper(container, {
                slidesPerView: 1,
                loop: true,
                spaceBetween: 25,
                autoplay: {
                  delay: 7000,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  1400: {
                    slidesPerView: 4,
                  },
                  1024: {
                      slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                },
              });
            }
            // 2.5-2-1.5-1.2-1
            if (container.classList.contains('swiper-25215121')) {
              new Swiper(container, {
                slidesPerView: 1,
                loop: true,
                spaceBetween: 25,
                autoplay: {
                  delay: 7000,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  1536: {
                    slidesPerView: 2.5,
                  },
                  1199: {
                    slidesPerView: 2,
                },
                  1024: {
                      slidesPerView: 1.5,
                  },
                  768: {
                    slidesPerView: 1.2,
                  },
                },
              });
            }
            // slidesPerView auto
            if (container.classList.contains('swiper-perview')) {
              new Swiper(container, {
                slidesPerView: 1,
                centeredSlides: true,
                loop: true,
                spaceBetween: 25,
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  1024: {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                  },
                  768: {
                    slidesPerView: 2,
                    centeredSlides: true,
                  },
                },
              });
            }
            // 3-2-1
            if (container.classList.contains('swiper-321')) {
              new Swiper(container, {
                slidesPerView: 1,
                spaceBetween: 25,
                initialSlide: 1, // Start with the second item
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  1024: {
                    slidesPerView: 3,
                    initialSlide: 0, 
                  },
                  768: {
                    slidesPerView: 2,
                    initialSlide: 0, 
                  },
                },
              });
            }
        });
    } catch (error) {
        console.error('Failed to load Swiper:', error);
    }
};

// Intersection Observer to check if swiper-container is in viewport
const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            lazyLoadSwiper();
            // Stop observing once loaded
            observer.unobserve(entry.target);
        }
    });
});

// Observe all swiper-container elements
const swiperContainers = document.querySelectorAll('.swiper-container');
swiperContainers.forEach(container => {
    observer.observe(container);
});