import "./lazyload-image.js";
import "./lazyload-video.js";
import "./swiper-new.js";
import "lightbox2/dist/css/lightbox.min.css";
import "./modal.js";
import "./collapse.js";
import "./lightbox.js";
import "./tooltip.js";
// import "./pricing-page-new.js";
import "./pricing-page-nov-2024.js";
import "./typed.js";
import "./placeholder-lazyload-image.js";
import "./event-addclass-header.js";
import "./wp-admin-set-margin.js";
import "./scroll-double-list.js";
import "./home-hoverplay-video.js";
// import "./gsap.js";

document.addEventListener("DOMContentLoaded", function () {
  // Trì hoãn việc tải các module không khẩn cấp
  function runWhenIdle(callback) {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(callback);
    } else {
      // Fallback cho các trình duyệt không hỗ trợ requestIdleCallback
      setTimeout(callback, 1000);
    }
  }
  runWhenIdle(() => {
    // Các import khác giữ nguyên
    import("./copy-clipboard.js");
    import("./smooth-scroll").then(({ scroll }) => {
      // Khởi tạo hoặc sử dụng scroll
    });

    const YoutubePopup = document.querySelectorAll("a.bla-1");
    if (YoutubePopup) {
      import("./YouTubePopUp.js");
    }

    // Trì hoãn import live-demo-submit.js trong 10 giây
    // setTimeout(() => {
    //   import("./live-demo-submit.js").then(() => {
    //     console.log("live-demo-submit.js đã được tải sau 10 giây");
    //   });
    //   import("./live-demo-modal-submit.js").then(() => {
    //     console.log("live-demo-modal-submit.js đã được tải sau 10 giây");
    //   });
    // }, 3000);

    // Play video demo
    const playVideoDemo = document.getElementById("play-video-demo");
    if (playVideoDemo) {
      playVideoDemo.addEventListener("click", function (event) {
        event.preventDefault();
        const player = new Vimeo.Player(document.getElementById("video-demo"));
        player.play();

        document.getElementById("video-demo").classList.remove("hidden");
        document.getElementById("image-video").classList.add("hidden");

        setTimeout(() => {
          document.getElementById("show-code").classList.add("active");
        }, 3000);
      });
    }

    // Popover live demo with event delegation
    const popover = document.querySelector(".popover");
    const wrapper = document.querySelector(".wrapper-popover");
    if (popover && wrapper) {
      document.body.addEventListener("click", function (event) {
        if (event.target.closest(".trigger-popover")) {
          console.log("trigger-popover");
          popover.classList.toggle("show-popover");
          wrapper.classList.toggle("active");
        } else if (
          event.target.matches(".close-button-popover") ||
          event.target === popover
        ) {
          popover.classList.remove("show-popover");
          wrapper.classList.remove("active");
        }
      });
    }

    // Modal live demo with event delegation
    const modal = document.querySelector(".modal-demo");
    if (modal) {
      document.body.addEventListener("click", function (event) {
        if (event.target.matches(".trigger")) {
          modal.classList.toggle("show-modal");
          document.body.style.overflow = "hidden";
        } else if (
          event.target.matches(".close-button") ||
          event.target === modal
        ) {
          modal.classList.remove("show-modal");
          document.body.style.overflow = "auto";
        }
      });
    }
  });

  
});
