// ES6 module
export function initModal() {
  MicroModal.init({
    onShow: (modal) => console.log(modal), // [1]
    onClose: (modal) => console.info(`${modal.id} is hidden`), // [2]
    openTrigger: "data-micromodal-open", // [3]
    closeTrigger: "data-micromodal-close", // [4]
    openClass: "is-open", // [5]
    disableScroll: true, // [6]
    disableFocus: false, // [7]
    awaitOpenAnimation: true, // [8]
    awaitCloseAnimation: true, // [9]
  });
}

document.addEventListener("DOMContentLoaded", () => {
  let modalLoaded = false;

  const loadModal = async () => {
    if (!modalLoaded) {
      const modalModule = await import("micromodal");
      initModal();
      modalLoaded = true;

      jQuery(document).ready(($) => {
        $('.data-micromodal-open').on('click', function (e) {
          e.preventDefault();
          $('#loading').removeClass('hidden');
          $('#myModal').html('');
          const post_id = $(this).attr('post-id');
          console.log(post_id);

          const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
          if (isMobile) {
            window.location.href = `${myAjax.homeurl}/?p=${post_id}`;
            return;
          }

          $.post(myAjax.ajaxurl, { action: 'load_post_content', post_id }, (response) => {
            $('#myModal').html(response.data);
            $('#loading').addClass('hidden');
          });
        });
      });
    }
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadModal();
        observer.disconnect();
      }
    });
  }, { root: null, threshold: 0.5 });

  // Select elements with either data-micromodal-open or using-modal attributes
  const elements = document.querySelectorAll("[data-micromodal-open], [using-modal]");
  elements.forEach((element) => observer.observe(element));

  elements.forEach((element) => {
    element.addEventListener("click", (event) => {
      event.preventDefault();
    });
  });
});
