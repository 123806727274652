
document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll('.scroll-infinite .items').forEach(list => {
    if(list){
      list.querySelectorAll('.scroll-infinite .items .item').forEach(item => {
        try {
          list.appendChild(item.cloneNode(true));
        } catch (e) {
          console.error('Error cloning item:', e);
        }
      });
    }
  });
});