

document.addEventListener("DOMContentLoaded", function () {
  const imgElements = document.querySelectorAll("img[data-srcset], img[data-src]");
  const lazyLoadingImage = (entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      // Assign data-srcset to srcset and data-src to src
      if (entry.target.dataset.srcset)  entry.target.srcset = entry.target.dataset.srcset;
      entry.target.src = entry.target.dataset.src; // optional, if needed for initial src
      
      entry.target.addEventListener("load", () => {
        observer.unobserve(entry.target); // Stop observing after loading
      });
      
      console.log("Image loaded");
    });
  };
  const lazyLoadingObserver = new IntersectionObserver(lazyLoadingImage, {
    threshold: 0.6,
  });
  imgElements.forEach((img) => lazyLoadingObserver.observe(img));
});