document.addEventListener("DOMContentLoaded", () => {
  const lazyVideos = document.querySelectorAll("video.lazy");

  const loadLazyVideo = (video) => {
    Array.from(video.children).forEach((source) => {
      if (source.tagName === "SOURCE") {
        source.src = source.dataset.src;
      }
    });
    video.currentTime = 0;
    video.load();
    video.classList.remove("lazy");
  };

  if ("IntersectionObserver" in window) {
    const lazyVideoObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const video = entry.target;

        // Load the video when it intersects the viewport
        if (entry.isIntersecting && video.classList.contains("lazy")) {
          loadLazyVideo(video);
          lazyVideoObserver.unobserve(video);
        }
      });
    }, { rootMargin: "200px" });

    const playbackObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const video = entry.target;

        if (entry.isIntersecting) {
          video.play();
        } else {
          video.pause();
        }
      });
    }, { threshold: 0.5 }); // Adjust threshold to control when to pause/play (50% visible)

    lazyVideos.forEach((lazyVideo) => {
      lazyVideoObserver.observe(lazyVideo);
      playbackObserver.observe(lazyVideo); // Observe for playback control
    });
  }

  // Handle videos in modal when modal is opened
  document.querySelectorAll(".data-micromodal-open").forEach((openModalButton) => {
    openModalButton.addEventListener("click", () => {
      const modalSelector = openModalButton.getAttribute("data-target");
      const modal = document.querySelector(modalSelector);

      if (modal) {
        modal.querySelectorAll("video.lazy").forEach((video) => {
          loadLazyVideo(video);
        });
      }
    });
  });
});
