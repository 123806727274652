
document.addEventListener("DOMContentLoaded", function () {
  // Tối ưu toggleMenu với caching
  window.toggleMenu = function () {
    const menu = document.querySelector(".menu-mobile");
    const burger = document.getElementById("burger-mobile");
    const body = document.body;

    if (burger) burger.classList.toggle("open");
    if (menu) menu.classList.toggle("active");
    body.style.overflow = body.style.overflow === "hidden" ? "auto" : "hidden";
  };
  const activateItem = (item) => {
    document.querySelectorAll(".item-4000.active").forEach((activeItem) => {
      const activeVideo = activeItem.querySelector("video");
      activeItem.classList.remove("active");
      activeVideo.pause();
    });
    item.classList.add("active");
    item.querySelector("video").play();
  };
  
  const deactivateItem = (item) => {
    item.classList.remove("active");
    item.querySelector("video").pause();
  };
  
  // Function to handle video actions based on screen width
  const handleVideo = () => {
    const isMobile = window.innerWidth < 768;
    document.querySelectorAll(".item-4000").forEach((item) => {
      const video = item.querySelector("video");
  
      if (isMobile) {
        // Autoplay for mobile
        video.play();
      } else {
        // Desktop: Play/pause on hover/touch
        item.addEventListener("mouseenter", () => activateItem(item));
        item.addEventListener("mouseleave", () => deactivateItem(item));
        item.addEventListener("touchstart", () => activateItem(item));
        item.addEventListener("touchend", () => deactivateItem(item));
        item.addEventListener("touchcancel", () => deactivateItem(item));
      }
    });
  };
  
  // Trigger video actions on page load and screen resize
  window.addEventListener("load", handleVideo);
  window.addEventListener("resize", handleVideo);
  
  // Auto-play the first video when target enters the viewport
  const target = document.getElementById("4000-companies-choose");
  if (target) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const firstItem = document.querySelector(".item-4000");
            if (firstItem) activateItem(firstItem);
          }
        });
      },
      { threshold: 0.5 }
    );
    observer.observe(target);
  }
});